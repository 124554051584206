import plasticCups from '../assets/img/plasticCups.jpeg';
import glassBottle from '../assets/img/glassBottle.png';

const products = [
    {
      id: 1,
      name: '60 Plastic Cups (180ml)',
      imageSrc: plasticCups,
      imageAlt: "Plastic water cups in 180ml",
      price: '2000 IQD',
    },
    {
      id: 2,
      name: '12 Glass Bottles (330ml)',
      imageSrc: glassBottle,
      imageAlt: "Front of men's Basic Tee in black.",
      price: '5750 IQD',
    },
    {
      id: 3,
      name: '6 Glass Bottles (750ml)',
      imageSrc: glassBottle,
      imageAlt: "Front of men's Basic Tee in black.",
      price: '8000 IQD',
      }
  ]
  
  export default function ProductList() {
    return (
      <div className="bg-white">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-xl sm:text-2xl font-extrabold tracking-tight text-gray-900">Our product line</h2>
  
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            {products.map((product) => (
              <div key={product.id} className="group relative">
                <div className="w-full min-h-60 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-60 lg:aspect-none">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.name}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  