import ProductList from './components/ProductList';

function App() {
  
  return (
    <div className="flex flex-col justify-between h-screen">
      <main className="mx-auto px-3 pb-12">
        
        <h1 className="w-auto font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-400 grid-center text-center text-4xl sm:text-8xl mt-10 sm:mt-16">Nova Fresh Water</h1>
        <p className="text-gray-700 ml-6 sm:ml-5 mt-3 sm:text-2xl">Top supplier of bottled water in Kurdistan</p>
        <p className="text-gray-600 ml-6 sm:ml-5 mt-3 sm:text-2xl">Pure, natural, mineral water with a
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-400"> crisp, refreshing, taste.</span></p>

        <div className="max-w-7xl mx-auto text-center py-6 px-4 sm:px-6 lg:py-10 lg:px-8">
          <h2 className="text-xl tracking-tight text-gray-900 sm:text-4xl">
            <span className="block max-w-2xl mx-auto">Interested in selling our products? <span className="font-bold">Contact us today.</span></span>
          </h2>
          <div className="shadow-lg rounded py-5 sm:rounded-lg bg-slate-50 mt-5">
                  <dl className="mt-2 text-xl text-gray-800">
                  <div>
                      <dt className="sr-only">Email</dt>
                      <dd className="font-bold text-blue-500"> <a href="mailto:info@novafreshwater.com?subject=Interested in selling your products">info@novafreshwater.com ✉️</a></dd>
                    </div>
                    <div className="mt-4">
                      <dt className="sr-only">Phone number</dt>
                      <dd className="font-bold text-blue-500"><a href="tel:07503038121">0750 303 8121 📱</a></dd>
                    </div>
                    <br />
                    <div>
                      <dt className="sr-only">Organization Name</dt>
                      <dd>Arvand Factory</dd>
                    </div>
                    <div>
                      <dt className="sr-only">Address</dt>
                      <dd>Timer Food Industrial Area</dd>
                      <dd>Erbil, Iraq 🇮🇶</dd>
                    </div>
                  </dl>
                </div>
        </div>

        <ProductList />

      <div className="flex flex-col justify-center items-center">
        <div className="sm:mt-15 sm:pt-5 max-w-2xl ml-5 text-gray-500 sm:text-xl text-md">
          <p className="mt-5">We provide pure, crisp, high quality water using the latest and most advanced purification methods. This gives Nova Fresh Water an exceptionally pure and uniquely mineralised flavour.</p>
          <p className="mt-5">We source our water from a sustainable artesian aquifer that is protected and preserved from external elements.</p>
          <p className="mt-5">That's why <span className="font-semibold">customers prefer <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-400">Nova Fresh Water</span> over any other brand</span>.</p>
        </div>
      </div>

      </main>
    </div>
  );
}

export default App;
